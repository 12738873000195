.skills {
    padding: 10% 6% 10%;
    width: 88%;
    height: auto;
    font-family: Inter, sans-serif;
    color: #263632;
    display: flex;
    flex-direction: column;
}

.skillsSection {
    color: #263632;
    display: flex;
    align-items: center;
    padding: 20px 0 0;
}

.skills .tail {
    padding: 1.5em 0 0 0;
}

#skillsExperience {
    padding: 0;
}

#skillsEducation {
    width: 100%;
    padding: 1.5em 0 0;
}

.skills .contentWrapper {
    width: 100%;
}

.skills .contentWrapper h3 {
    margin-block: 0 0.5em;
    pointer-events: none !important;
}

.skills .contentWrapper h5 {
    margin-block: 0 0.5em;
}

.skills .contentWrapper p {
    margin-block: 0;
    line-height: 1.5em;
    font-size: smaller;
    text-indent: -12px;
    padding-left: 12px;
}

.skillsSection div div {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.skills a h5 {
    color: #263632;
    margin-block: 0 20px;
    margin-inline: 6%;
    font-size: 0.75em;
}

.skills a h5:hover {
    color: #458ff8;
    cursor: pointer;
}

#resume_link h5 {
    color: #263632;
    margin-block: 1.5em 0;
    margin-inline: 0;
    font-size: 0.9em;
}

#resume_link h5:hover {
    color: #255fb1;
    cursor: pointer;
}

@media screen and (min-width: 500px) {
    .skills .contentWrapper p,
    .skills .contentWrapper h5 {
        font-size: small;
    }

    .skills .contentWrapper h3 {
        font-size: medium;
    }

    .whiteBackground {
        text-align: start;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 650px) {
    #skillsExperience {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media screen and (min-width: 900px){
    .skills .contentWrapper p,
    .skills .contentWrapper h5 {
        font-size: medium;
    }

    .skills .contentWrapper h3 {
        font-size: larger;
    }

    .skills {
        display: flex;
        justify-content: space-between;
    }

    .skills .tail {
        padding: 2.0em 0 0;
    }

    .skillsSection {
        color: #263632;
        display: flex;
        align-items: center;
        padding: 20px 0 0;
    }
    .skillsSection {
        width: 30%;
        justify-content: flex-start;
        flex-direction: row;
        align-items: flex-start;
        color:#263632;
        /*padding: 3% 3% 5% 3%;*/
        margin-block: 2em 0;
    }

    #skillsExperience {
        gap: 5%;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1200px){
    .skills .contentWrapper p,
    .skills .contentWrapper h5 {
        font-size: large;
    }

    .skills .contentWrapper h3 {
        font-size: larger;
    }

    #skillsExperience {
        width: 65%;
    }

    #skillsEducation {
        width: 30%;
        padding: 0;
    }

    .skills {
        flex-direction: row;
    }

    .skills .tail {
        /*padding: 20px 0 0 5%;*/
        padding: 2.0em 0 0;
    }

    #resume_link h5 {
        font-size: 1em;
    }
}