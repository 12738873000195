/*
.blue_wrapper {
    background-color: #CBDCF4;
    padding-top: 20%;
}

.white_wrapper {
    background-color: white;
    padding-top: 20%;
}
*/

h2:hover {
    pointer-events: none !important;
}

.Portfolio img {
    max-width: 1900px;
}

.Portfolio {
    margin-left: auto;
    margin-right: auto;
}

.links {
    display: flex;
    flex-direction: column;
    padding: 2em 6%;
    color: rgb(95, 100, 90);
    background-color: rgb(204, 209, 200); 
}
.links a:link {
    color: white;
}
.links h3 {
    margin-block: 0 1em;
}

.links h5 {
    margin-block: 0 0.25em;
}
.links h5:hover {
    color:#255fb1;
    cursor: pointer;
}

.module {
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    color: #263632;
    width: 100%;
}

.imgmodule {
    display: flex;
    flex-direction: row;
    padding-top: 2%;
    justify-content: flex-end;
}

.modBlock {
    display: flex;
    flex-direction: column;
    margin: 0 6%;

}
.modBlock h2 {
    margin-block: 0 0;
    pointer-events: none !important;
}
.modBlock h5 {
    margin-block: 0.5em 0;
}
.modBlock p {
    margin-block: 0.5em 0;
}

.imgBlock {
    padding: 5% 6% 0;
    overflow: hidden;
}

.scrollBlock {
    padding: 5% 6% 0;
    overflow: scroll;
}

.portfolioFigure {
    height: auto;
    width: 100%;
    border-radius: 10px;
    /*box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);*/
}
.noshadow {
    width: 100%;
    height: auto;
    box-shadow: none;
}

#ds1, #ds2 {
    height: auto;
    width: 100%;
    overflow: hidden;
}

.portfolioimg {
    height: auto;
    width: 200%;
}

.projectBanner {
    width: 100%;
    height: 400px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
}
#dm_banner {
    height: auto;
}

.scrollBlock {
    height: 600px;
}

.scrollDiv {
    width: 88%;
    height: 500px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    margin: 5% 6% 0;
}

.bannerImage, .scrollImage {
    width: auto;
    height: 100%;
    object-fit: cover;
}

#note {
    margin: 5% 6% 0;
    width: 88%;
    font-size: small;
}

#lc8 img, #lc6 img {
    border-radius: 0;
}

.arrayBlock {
    margin: auto;
}

.arrayImage {
    width: 100%;
    height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageArray {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    width: 100%;
}

.leftArrow, .rightArrow {
    font-size: 1.5em;
    font-weight: bold;
    margin-block: 0;
    margin-inline: 2% 0;
}

.leftArrow {
    margin-inline: 1%;
}

.rightArrow {
    margin-inline: 1%;
}

.leftArrow:hover, .rightArrow:hover {
    cursor: pointer;
    color:#255fb1;
}

.arrayBlock .caption {
    margin-block: 10px 0;
    margin-inline: 6%;
    font-size: small;
    font-weight: 400;
    text-align: right;
}

.arrayBlock .caption strong {
    font-weight: 600;
}

.imageSwitch {
    width: 100%;
    height: 100%;
    box-shadow: none;
    object-fit: cover;
}

.arrayCaption {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

/* Tablet and Desktop Styling */
@media screen and (min-width: 500px){
    .module {
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5%;
    }

    .imgBlock {
        max-width: 500px;
        margin: auto;
    }

    .arrayBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .arrayBlock {
        max-width: 80%;
    }
}

@media screen and (min-width: 700px){
    .scrollBlock {
        overflow: hidden;
        padding: 0 6%;
        max-height: 90vh;
    }

    .scrollBlock img {
        max-width: 100%;
        padding-top: 5%;
    }

    .scrollDiv {
        height: 600px;
    }

    .projectBanner {
        width: 100%;
    }

    .arrayImage {
        height: 400px;
    }

    .arrayBlock {
        max-width: 80%;
    }
}

@media screen and (min-width: 900px){
    /*
    .blue_wrapper {
        background-color: #CBDCF4;
        padding-top: 10%;
    }
    
    .white_wrapper {
        background-color: white;
        padding-top: 10%;
    }
    */

    #note {
        width: 50%;
    }

    .module0 {
        flex-direction: row;
        padding-top: 5%;
    }

    .modBlock {
        display: flex;
        margin: 0 6%;
        min-width: 40%;
    }
    .modBlock p {
        /*width: 50%;*/
    }

    .module {
        flex-direction: row;
        justify-content: space-between;
    }

    .imgmodule {
        flex-direction: row;
        justify-content: flex-end;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5%;
    }

    .imgBlock {
        display: flex;
        justify-content: flex-end;
        padding: 0 6%;
        width: 100%;
    }

    .imgBlock img {
        max-width: 500px;
    }

    .scrollBlock {
        overflow: hidden;
        padding: 0 6%;
        max-height: 90vh;
    }

    .noshadow {
        width: 100%;
        height: auto;
        box-shadow: none;
    }

    .portfolioFigure {
        width: 100%;
        height: auto;
    }
    

    #ip1 {
        max-width: 1900px;
    }

    #wp1 {
        max-width: 1900px;
    }
    #wp2 {
        max-width: 1300px;
    }
    #ep1 {
        max-width: 1900px;
    }
    #ep2 {
        max-width: 1300px;
    }

    #a0 {
        max-width: 1900px;
    }

    #rp1, #rp3, #rp5, #rp7 {
        max-width: 1900px;
    }

    #re1, #re2 {
        max-width: 1900px;
        /*margin: 0 0 0 auto;*/
    }
    
    #mr2, #mr4, #mr5, #mr9 {
        max-width: 1900px;
    }

    #mr5, #mr10 {
        max-width: 1900px;
        margin: 0 0 0 auto;
    }

    #mr8 {
        width: 100%;
        max-height: 100%;
        overflow: auto;
    }

    #ds0 {
        max-width: 850px;
        margin-left: 3%;
    }
    #ds1, #ds2 {
        max-width: 1700px;
    }

    #portfolioimg {
        /*height: auto;
        width: 100%;*/
        max-width: 100%;
        max-height: 100%;
    }

    #lc9 .imgBlock, #ip0 .imgBlock {
        display: block;
    }

    .scrollDiv {
        width: 100%;
    }

    .projectBanner {
        height: 500px;
    }

    .arrayBlock {
        max-width: 750px;
    }
}
