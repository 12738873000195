.banner {
    margin-block: 0 0;
    display: flex;
    width: 100%;
    height: auto;
    max-height: 750px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.banner img {
    width: 100%;
    height: auto;
    max-height: 70%;
    object-fit: cover;
    object-position: bottom;
}

@media screen and (min-width: 500px) {
    .banner {
        max-height: 1000px;
    }

    .banner img {
        object-fit: cover;
        object-position: 0 80%;
    }
}

@media screen and (min-width: 1650px) {
    .banner {
        max-height: 1000px;
    }

    .banner img {
        object-fit: cover;
        object-position: 0 80%;
    }
}