.intro {
    width: 100%;
    height: auto;
    font-family: Inter, sans-serif;
    /*color: #fff;*/
    color: #263632;
  }
  
  .intro .background {
    /*background-color: #9fa69a;
    box-shadow: 0 5px 5px rgba(37, 36, 36, 0.1);*/
    color: #263632;
    display: flex;
    align-items: flex-end;
    padding: 10% 6% 0;
  }
  
  .intro h3 {
    margin-block: 0;
    color: #263632;
    font-size: small;
    pointer-events: none !important;
  }
  
  .intro p {
    margin-block: 0.5em 0;
    font-size: small;
    color: #263632;
  }
  
  .intro div {
    display: flex;
  }
  
  .intro div div {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
  }
  
  .intro img {
    height: 250px;
    width: 50%;
    object-fit: cover;
    /*max-width: 180px;
    border-radius: 50%;*/
  }

  @media screen and (min-width: 500px){
    .intro p, .intro h3 {
      font-size: small;
    }
  
    .intro img {
      height: 300px;
      width: 30%;
    }

    .intro div div {
        width: 42%;
    }
  }

  @media screen and (min-width: 700px){
    .intro img {
      height: 300px;
    }
  }
  
  @media screen and (min-width: 900px){  
    .intro p, .intro h3 {
      font-size: medium;
    }
  
    .intro img {
        height: 400px;
    }
  }
  
  @media screen and (min-width: 1500px){
    .intro p, .intro h3 {
      font-size: large;
    }
  
    .intro img {
      height: auto;
      width: 31%;
    }

    .intro div div {
        margin-left: 3.5%;
      }
  }