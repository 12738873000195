.footer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.footer header {
    text-align: end;
}

.footer header:hover {
    color: #255fb1;
    cursor: pointer;
}

.footer h3:hover, #page_link:hover {
    color: #255fb1;
    cursor: pointer;
}

.announcement {
    background-color: rgb(204, 209, 200);
    min-height: 25px;
    color: rgb(95, 100, 90);;
    letter-spacing: 1px;
    padding: 1.5% 6%;
    display: flex;
    justify-content: flex-end;
    font: 700 10px/18px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
}

.iconContainer {
    display: flex;
    padding: 1.5% 6%;
}

.iconWrapper {
    background-color: #fff;
    display: flex;
    height: 35px;
    flex-grow: 1;
    gap: 10px;
    align-items: center;
}

.icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 20px;
}

.lastIcon {
    composes: icon;
    align-self: start;
    margin-top: 7px;
}

svg {
    fill: white;
    stroke: #263632;
}
svg:hover {
    stroke: #458ff8 !important;
    fill: white;
    cursor: pointer;
}

#page_link {
    font-size: 1.25em;
}

/*
*
* // website styling defaults to mobile platforms, alternative styling applied for desktop...
*/
@media screen and (min-width: 500px) {
    .iconContainer {
        padding: 10px 6%;
    }

    .iconWrapper {
        gap: 15px;
    }

    .footer h3 {
        font-size: small;
    }
}

@media screen and (min-width: 900px){
    .footer h3 {
        font-size: medium;
    }
}

@media screen and (min-width: 1500px){
    .footer h3 {
        font-size: large;
    }
}